import React from "react";
import ModalComponent from "../../../components/modals";
import LaddaButton from "../../../components/buttons/laddaButton";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Grid } from "@mui/material";

const validationSchema = yup.object().shape({
  effective: yup.boolean().required("Este campo es obligatorio"),
});

const EditDevice = ({ open, handleClose, data }) => {
  return (
    <div>
      <ModalComponent
        open={open}
        handleClose={handleClose}
        title={""}
        actions={<div className="HContainer j-center w-complete"></div>}
      >
        <Grid container rowSpacing={2} className="mv-25">
          <Grid item xs={6} sm={6} md={6}>
            <p class="detail-label">Item</p>
            <p>{data.item}</p>
          </Grid>
        </Grid>
      </ModalComponent>
    </div>
  );
};

export default EditDevice;
