import React, { useState } from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";

const LaddaButton = ({
  children,
  variant,
  color,
  onClick,
  backgroundColor,
  ladda,
  width,
  margin,
  borderColor,
  fontSize,
  borderRadius,
  height,
  disabled,
  ...rest
}) => {
  const [loading, setLoading] = useState(false);

  const ColorButton = styled(Button)(({ theme }) => ({
    width: width ? width : "auto",
    fontSize: fontSize ? fontSize : "auto",
    color: color ? color : "#ffffff",
    backgroundColor: backgroundColor ? backgroundColor : "#6A6AD8",
    height: height ? height : "40px",
    textTransform: "initial",
    boxShadow: "none",
    minWidth: "50px",
    border: `2px solid ${
      borderColor
        ? borderColor
        : backgroundColor
        ? backgroundColor
        : "transparent"
    }`,
    borderRadius: borderRadius ? borderRadius : "7px",
    "&:hover": {
      opacity: 1.5,
      border: `3px solid ${borderColor ? borderColor : backgroundColor}`,
      backgroundColor: backgroundColor ? backgroundColor : "#6A6AD8",
    },
    margin: margin ? margin : "5px 5px",
  }));

  const handleButtonClick = async (event) => {
    setLoading(true);
    try {
      await onClick(event);
    } catch (error) {
      console.error(error);
    }
    finally {
      setLoading(false);
    }
  };

  return (
    <ColorButton
      variant={variant}
      onClick={handleButtonClick} 
      disabled={disabled || loading}
      {...rest}
    >
      {children}
    </ColorButton>
  );
};

export default LaddaButton;
