import React from "react";
import HighchartsComponent from "../../../components/highchartsComponent";
import { horizontalColumn } from "../../../components/highchartsComponent/charts/horizontalColumn";
import { Divider } from "@mui/material";
import { Carousel } from "primereact/carousel";

const CompetenceGraphSection = ({ type, data, umbral }) => {
  const graphTemplate = (competence) => {
    const graphData = getCompetenceGraphData(competence.id);
    return (
      <div className="graph-template-container">
        <HighchartsComponent
          options={horizontalColumn(
            graphData.competenceName,
            "",
            graphData.series,
            graphData.categories,
            umbral
          )}
        />
      </div>
    );
  };

  const getCompetenceGraphData = (competenceId) => {
    const questionGraph = data.questiosGraphData.filter(
      (item) => item.competenceId === competenceId
    )[0];
    return questionGraph;
  };

  return (
    <div>
      {type === 0 ? (
        <>
          <HighchartsComponent
            options={horizontalColumn(
              "Grafica 360°",
              "",
              data.graph360GraphDataSpecific.series || [],
              data.graph360GraphDataSpecific.categories || [],
              umbral
            )}
          />
          <HighchartsComponent
            options={horizontalColumn(
              "Competencia",
              "",

              data.competenceGraphDataSpecific.series || [],
              data.competenceGraphDataSpecific.categories || [],
              umbral
            )}
          />
          <Divider className="mv-25" />
          <Carousel
            value={data.carouselTitles}
            numScroll={1}
            numVisible={1}
            itemTemplate={graphTemplate}
          />
        </>
      ) : (
        <>
          <HighchartsComponent
            options={horizontalColumn(
              "Grafica 360°",
              "",
              data.graph360GraphDataOrganizational.series || [],
              data.graph360GraphDataOrganizational.categories || [],
              umbral
            )}
          />
          <HighchartsComponent
            options={horizontalColumn(
              "Competencia",
              "",

              data.competenceGraphDataOrganizational.series || [],
              data.competenceGraphDataOrganizational.categories || [],
              umbral
            )}
          />
          <Divider className="mv-25" />
          <Carousel
            value={data.carouselTitles}
            numScroll={1}
            numVisible={1}
            itemTemplate={graphTemplate}
          />
        </>
      )}
    </div>
  );
};

export default CompetenceGraphSection;
