import React, {  useState } from "react";
import ModalComponent from "../../../components/modals";
import LaddaButton from "../../../components/buttons/laddaButton";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Grid } from "@mui/material";
import FormTextField from "../../../components/textField/formTextField";
import { callApiAsync, getCallVerifySerie } from "../../../services/apiService";
import ToastNotification from "../../../components/toastNotification";

const validationSchema = yup.object().shape({
  newSerieNumber: yup
    .string()
    .required("Este campo es obligatorio")
    .min(1, "Este campo no puede estar vacío")
});

const AddSerieNumber = ({
  open,
  close,
  setSerieIdSelected,
  setSerieSelected
}) => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isValid }
  } = useForm({
    resolver: yupResolver(validationSchema)
  });

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const handleClose = () => {
    reset();
    close();
  };

  const onSubmitNewSerie = (formData) => {

    callApiAsync(
      () => getCallVerifySerie(formData.newSerieNumber),
      (data) => {
        if (data.id === 0) {
          setSerieIdSelected(data.id);
          setSerieSelected(formData.newSerieNumber);
          handleClose();
        } else {
          setToastMessage("Número de serie ya existe.");
          setShowToast(true);
        }
      }
    );
  };

  return (
    <div>
      <ToastNotification
        severity="error"
        summary={toastMessage}
        detail=""
        showToast={showToast}
        setShowToast={setShowToast}
      />

      <ModalComponent
        open={open}
        handleClose={() => {
          handleClose();
        }}
        title={""}
        actions={<div className="HContainer j-center w-complete"></div>}
      >
        <div className="HContainer">
          <form
            className="VContainer align-center m-20"
            onSubmit={handleSubmit((formData) => onSubmitNewSerie(formData))}
          >
            <Grid container rowSpacing={2}>
              <Grid item xs={12}>
                <p className="create-label">Nuevo Nro. de Serie:</p>
                <FormTextField
                  name="newSerieNumber"
                  control={control}
                  error={errors}
                />
              </Grid>
            </Grid>
            <LaddaButton
              width={"180px"}
              fontSize={"12px"}
              height={"32px"}
              variant="contained"
              margin={"20px 0"}
              type="submit"
              disabled={!isValid}
            >
              <p className="button_text">Crear Nro de Serie</p>
            </LaddaButton>
          </form>
        </div>
      </ModalComponent>
    </div>
  );
};

export default AddSerieNumber;
