export const biomedicalAccesoriesColumns = [
  { field: "accessory", header: "Accesorio" },
  { field: "id", header: "id", columnType: "hide" },
  { field: "brandId", header: "brandId", columnType: "hide" },
  { field: "brandAccessory", header: "Marca" },
  { field: "partNumberAccessory", header: "Número de Parte" },
  { field: "quantity", header: "Cantidad" },
  {
    field: "actionButtons",
    header: "",
    columnType: "actionButtons",
  },
];

export const accesoriesColumns = [
  { field: "accessory", header: "Accesorio" },
  { field: "id", header: "id", columnType: "hide" },
  {
    field: "actionButtons",
    header: "",
    columnType: "actionButtons",
  },
];

export const technicalFeaturesColumns = [
  { field: "featureName", header: "Caracteristica" },
  { field: "id", header: "id", columnType: "hide" },
  { field: "featureId", header: "featureId", columnType: "hide" },
  { field: "description", header: "Descripción" },
  {
    field: "actionButtons",
    header: "",
    columnType: "actionButtons",
  },
];
