import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import FormTextField from "../../../components/textField/formTextField";
import LaddaButton from "../../../components/buttons/laddaButton";
import ToastNotification from "../../../components/toastNotification";

import {
  callApiAsync,
  getCallResumeFeatureData,
  postAddAccessoryData,
  postSaveBiomedicalFeature,
} from "../../../services/apiService";
import SimpleSelect from "../../../components/selects/simpleSelect";
import { icons } from "../../../constants";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import ModalComponent from "../../../components/modals";
import AddFeature from "./addFeature";

const validationSchema = yup.object({
  amountAccessory: yup
    .number()
    .required("La cantidad es obligatoria")
    .integer("La cantidad debe ser un número entero")
    .positive("La cantidad debe ser positiva"),
  technicalFeatures: yup.string().required("La característica es obligatoria"),
  technicalFeaturesDescription: yup
    .string()
    .required("La descripción es obligatoria")
});

const SelectFeatureModal = ({
  open,
  close,
  getResumeFeatureData,
  selectedRow,
  setSelectedRow,
  biomedicalId,
  featuresData
}) => {
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [featureOptions, setFeatureOptions] = useState([]);

  const [openAddFeatureModal, setOpenAddFeatureModal] = useState(false);

  const hadleCloseAddFeature = () => {
    setOpenAddFeatureModal(false);
  };

  const {
    control,
    formState: { errors },
    getValues,
    reset,
    setValue
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "all"
  });

  const getResumeFeatureDataM = () => {
    callApiAsync(
      () => getCallResumeFeatureData(biomedicalId),
      (data) => {
        setFeatureOptions(data.list);
      }
    );
  };
  const handleSaveFeature = () => {
    const selectedFeature = getValues("technicalFeatures");
    const description = getValues("technicalFeaturesDescription");
    var isDuplicate = false;
    if (selectedRow != null) {
      isDuplicate = featuresData.some(
        (feature) =>
          feature.featureId === selectedFeature && feature.id !== selectedRow.id
      );
    } else {
      isDuplicate = featuresData.some(
        (feature) => feature.featureId === selectedFeature
      );
    }

    if (isDuplicate) {
      setToastMessage("Esta característica ya está agregada.");
      setShowToast(true);
      return;
    }

    const objToSend = {
      id: selectedRow ? selectedRow.id : 0,
      featureId: selectedFeature,
      biomedicalEquipmentId: biomedicalId,
      description: description
    };

    callApiAsync(
      () => postSaveBiomedicalFeature(objToSend),
      () => {
        setToastMessage("Característica agregada exitosamente.");
        setShowToast(true);
        getResumeFeatureData();
        cleanData();
        handleClose();
      }
    );
  };

  useEffect(() => {
    if (!open) return;
    getResumeFeatureDataM();
    if (selectedRow) {
      loadData(selectedRow);
    } else {
      cleanData();
    }
  }, [open]);

  const loadData = (data) => {
    setValue("technicalFeatures", data.featureId);
    setValue("technicalFeaturesDescription", data.description);
  };

  const cleanData = () => {
    setValue("technicalFeatures", "");
    setValue("technicalFeaturesDescription", "");
  };

  const handleClose = () => {
    setSelectedRow(null);
    reset();
    close();
  };

  return (
    <div>
      <ModalComponent
        open={open}
        handleClose={() => {
          handleClose();
        }}
        title={"Agregar caracteristica técnica"}
        actions={<div className="HContainer j-center w-complete"></div>}
      >
        <div>
          <ToastNotification
            severity="warn"
            summary={toastMessage}
            detail=""
            showToast={showToast}
            setShowToast={setShowToast}
          />
          <Grid container justifyContent="center" spacing={2}>
            <form style={{ width: "100%" }}>
              <Grid container spacing={2}>
                <Grid item xs={9} md={4}>
                  <p className="create-label">Característica:</p>
                  <SimpleSelect
                    name="technicalFeatures"
                    control={control}
                    options={featureOptions}
                    hideLabel={true}
                    error={errors.technicalFeatures}
                  />
                </Grid>
                <Grid item xs={3} md={2} className="VContainer align-end">
                  <div>
                    <LaddaButton
                      width={"40px"}
                      fontSize={"12px"}
                      height={"40px"}
                      variant="contained"
                      margin={"0px 0px 0px 25px"}
                      onClick={() => {
                        setOpenAddFeatureModal(true);
                      }}
                    >
                      <img
                        className="btn-icon"
                        src={icons.plusIcon}
                        alt="icon"
                      />
                    </LaddaButton>
                  </div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <p className="create-label">Descripción:</p>
                  <FormTextField
                    name="technicalFeaturesDescription"
                    control={control}
                    label=""
                    error={errors.technicalFeaturesDescription}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  {selectedRow == null && (
                    <LaddaButton
                      variant="contained"
                      disabled={selectedRow != null}
                      onClick={handleSaveFeature}
                    >
                      <p className="button_text">
                        Agregar Característica Nueva
                      </p>
                    </LaddaButton>
                  )}
                  {selectedRow != null && (
                    <LaddaButton
                      variant="contained"
                      disabled={selectedRow == null}
                      onClick={() => {
                        handleSaveFeature();
                      }}
                    >
                      <p className="button_text">Guardar</p>
                    </LaddaButton>
                  )}
                </Grid>
              </Grid>
            </form>
          </Grid>
          <AddFeature
            open={openAddFeatureModal}
            close={hadleCloseAddFeature}
            setFeatureOptions={setFeatureOptions}
            featureOptions={featureOptions}
          />
        </div>
      </ModalComponent>
    </div>
  );
};

export default SelectFeatureModal;
