import { Grid } from "@mui/material";
import React from "react";
import ImageUploadButton from "../../../components/ImageUploadBtn";
import "./styles.css";

const AnnexesPhotographicEvidence = ({ images, setImages, isApprover }) => {
  const handleImageChange = async (file, imageKey) => {
    if (file) {
      const updatedImages = {
        ...images,
        [imageKey]: {
          ...images[imageKey],
          path: URL.createObjectURL(file),
          file: URL.createObjectURL(file)
        }
      };
      setImages(updatedImages);
    } else {
      const updatedImages = {
        ...images,
        [imageKey]: {
          ...images[imageKey],
          path: null,
          file: null
        }
      };
      setImages(updatedImages);
    }
  };

  const handleDescriptionChange = (description, imageKey) => {
    const updatedImages = {
      ...images,
      [imageKey]: {
        ...images[imageKey],
        description: description
      }
    };
    setImages(updatedImages);
  };

  return (
    <div>
      <br />
      <p className="createRist-title mt-25">
        3. Anexos y Evidencias Fotográficas
      </p>
      <Grid container justifyContent="center" spacing={2}>
        {Object.keys(images).map((key, index) => (
          <Grid item xs={12} md={6} key={index}>
            <div className="form-group">
              <p className="create-label m-0">
                Imagen del equipo biomédico {index + 1}
              </p>
              <ImageUploadButton
                path={images[key].path}
                onImageChange={(file) => handleImageChange(file, key)}
                buttonText={`Seleccionar Imagen`}
                disabled={isApprover}
              />
              <input
                type="text"
                value={images[key].description || ""}
                onChange={(e) => handleDescriptionChange(e.target.value, key)}
                placeholder={`Descripción de la imagen`}
                className="custom-text-input"
                maxLength={200}
                disabled={isApprover}
              />
            </div>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default AnnexesPhotographicEvidence;
