import { Tooltip, styled, tooltipClasses } from "@mui/material";

export const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "white",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
    color: "#00000",
    fontWeight: 700,
    padding: "15px 20px",
    fontSize: theme.typography.pxToRem(12),
    borderRadius: "10px",
    fontFamily: "Lato",
  },
}));
