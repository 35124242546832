export const Page360report = {
  organizationalComparative: "360REP_ORGANIZATIONAL_COMPARATIVE",
  organizationalTotal360: "360REP_ORGANIZATIONAL_TOTAL360",
  specificComparative: "360REP_SPECIFIC_COMPARATIVE",
  specificTotal360: "360REP_SPECIFIC_TOTAL360",
  observationsEdit: "360REP_OBSERVATIONS_EDIT"
};

export function isRoleUtil(role, roleArray) {
  return roleArray.includes(role);
}
