import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import FormTextField from "../../../components/textField/formTextField";
import LaddaButton from "../../../components/buttons/laddaButton";
import {
  callApiAsync,
  postRespondInspectionRecord,
} from "../../../services/apiService";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import ModalComponent from "../../../components/modals";
import { inspectionRecordProcess } from "../../../constants/enums";
import { useNavigate } from "react-router-dom";

const validationSchema = yup.object().shape({});

const DeclineInspectionRecordModal = ({
  open,
  close,
  bioEquipmentId,
  inspectionRecordId,
  comments,
}) => {
  const navigate = useNavigate();

  const {
    control,
    formState: { errors },
    getValues,
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "all",
  });
  useEffect(() => {
    if (!open) return;
    loadData();
  }, [open]);

  const onSubmit = () => {
    const objToSend = {
      comments: getValues("comments"),
      biomadicalEquipmentId: bioEquipmentId,
      inspectionRecordId: inspectionRecordId,
      state: inspectionRecordProcess.pending,
    };
    callApiAsync(
      () => postRespondInspectionRecord(objToSend),
      () => {
        cleanData();
        navigate("/biomedicalManagementDashboard/manageDevice");
        handleClose();
      }
    );
  };

  const loadData = () => {
    setValue("comments", comments);
  };
  const cleanData = () => {
    setValue("comment", "");
  };

  const handleClose = () => {
    cleanData();
    reset();
    close();
  };

  return (
    <div>
      <ModalComponent
        open={open}
        handleClose={() => {
          handleClose();
        }}
        title={"Rechazar acta"}
        actions={<div className="HContainer j-center w-complete"></div>}
      >
        <Grid container className="mv-25 min-w-700">
          <Grid item xs={12} md={12}>
            <div className="VContainer j-center w-675">
              <p className="text-form-label" style={{ marginLeft: "10px" }}>
                Comentario:
              </p>
              <FormTextField
                name="comments"
                control={control}
                isTextArea={true}
                rows={2}
              />
              <div className="HContainer j-center mv-25">
                <LaddaButton
                  width={"160px"}
                  fontSize={"12px"}
                  height={"40px"}
                  variant="contained"
                  backgroundColor={"#F43F5E"}
                  onClick={() => {
                    onSubmit();
                  }}
                >
                  <p className="button_text">Rechazar</p>
                </LaddaButton>
              </div>
            </div>
          </Grid>
        </Grid>
      </ModalComponent>
    </div>
  );
};

export default DeclineInspectionRecordModal;
