import React, { useState, useEffect } from "react";
import SimpleTable from "../../../components/tables/simpleTable";
import FormRadioButton from "../../../components/formRadioButton";
import ModalComponent from "../../../components/modals";
import FormTextField from "../../../components/textField/formTextField";
import { icons } from "../../../constants";
import LaddaButton from "../../../components/buttons/laddaButton";
import {
  callApiAsync,
  getCallInspectionProtocolData,
  postDeleteInspectionProtocol,
  postSaveInspectionProtocol,
} from "../../../services/apiService";
import { useForm } from "react-hook-form";
import { Grid } from "@mui/material";

const options = [
  { label: "Sí", value: 1 },
  { label: "No", value: 2 },
  { label: "N/A", value: 3 },
];

const inspectionProtocolColumns = [
  { field: "activity", header: "Actividades " },
  { field: "optionValueTx", header: "Estado" },
  {
    field: "actionButtons",
    header: "",
    columnType: "actionButtons",
  },
];

const InspectionProtocol = ({ bioEquipmentId, isApprover }) => {
  const [openModal, setOpenModal] = useState(false);
  const [activityTable, setActivityTable] = useState("");
  const [selectedActivity, setSelectedActivity] = useState(null);
  const [selectedOption, setSelectedOption] = useState(1);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const {
    control,
    formState: { errors },
    getValues,
    reset,
    setValue,
  } = useForm({
    mode: "all",
  });

  useEffect(() => {
    getInspectionProtocolData();
  }, []);

  useEffect(() => {
    if (selectedActivity) {
      setValue("selectedOption", selectedActivity.optionValue);
    }
  }, [selectedActivity, setValue]);

  const getInspectionProtocolData = () => {
    callApiAsync(
      () => getCallInspectionProtocolData(bioEquipmentId),
      (data) => {
        const mappedTable = data.table.map((item) => {
          const option = options.find((opt) => opt.value === item.optionValue);
          return {
            ...item,
            optionValueTx: option ? option.label : item.optionValue,
          };
        });

        setActivityTable(mappedTable);
      }
    );
  };

  const handleOpenModal = (activity = null) => {
    if (activity) {
      setValue("newActivity", activity.activity);
      setSelectedOption(activity.optionValue);
    } else {
      setSelectedActivity(null);
      setValue("newActivity", "");
      setSelectedOption("");
      setValue("selectedOption", null); 
      setSelectedOption(null); 
    }
    setOpenModal(true);
  };

  const handleOpenDeleteModal = (activity) => {
    setSelectedActivity(activity);
    setOpenDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setSelectedOption("");
    setValue("selectedOption", "");
    reset();
    setOpenDeleteModal(false);
    setSelectedActivity(null);
  };

  const handleDeleteActivity = () => {
    callApiAsync(
      () => postDeleteInspectionProtocol({ id: selectedActivity.id }),
      () => {
        handleCloseDeleteModal();
        getInspectionProtocolData();
      }
    );
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedActivity(null);
    setSelectedOption("");
  };

  const handleSaveActivity = () => {
    const activityData = {
      id: selectedActivity == null ? 0 : selectedActivity.id,
      activity: getValues("newActivity"),
      optionValue: selectedOption,
      bioEquipmentId: bioEquipmentId,
    };
    callApiAsync(
      () => postSaveInspectionProtocol(activityData),
      (data) => {
        handleCloseModal();
        getInspectionProtocolData();
      }
    );
  };

  return (
    <div className="page-break">
      <p className="createRist-title mt-25">2. Protocolo de Inspección</p>

      <div className="mt-15 no-print">
        <LaddaButton
          variant="contained"
          onClick={() => handleOpenModal()}
          disabled={isApprover}
        >
          <p className="button_text">Agregar Actividad</p>
        </LaddaButton>
      </div>

      <SimpleTable
        data={activityTable}
        columns={inspectionProtocolColumns}
        control={control}
        buttons={[
          {
            type: "icon",
            icon: icons.editBlue,
            onClick: (rowData) => {
              setSelectedActivity(rowData);
              handleOpenModal(rowData);
            },
          },
          {
            type: "icon",
            icon: icons.closeRed,
            onClick: (rowData) => {
              setSelectedActivity(rowData);
              handleOpenDeleteModal(rowData);
            },
          },
        ]}
      />

      <ModalComponent
        open={openModal}
        handleClose={handleCloseModal}
        title={
          selectedActivity != null
            ? "Editar Actividad"
            : "Agregar Nueva Actividad"
        }
        actions={<div className="HContainer j-center w-complete"></div>}
      >
        <div className="HContainer form-modal">
          <Grid container rowSpacing={2}>
            <Grid item xs={8} >
              <FormTextField
                name="newActivity"
                control={control}
                label="Actividad"
              />
            </Grid>
            <Grid item xs={6}>
              <FormRadioButton
                name="selectedOption"
                control={control}
                options={options}
                label="Seleccione una opción"
                onChange={(value) => setSelectedOption(value)}
              />
            </Grid>
          </Grid>
        </div>
        <div className="HContainer form-modal j-center">
          <LaddaButton variant="contained" onClick={handleSaveActivity}>
            <p className="button_text">
              {selectedActivity != null ? "Guardar" : "Agregar"}
            </p>
          </LaddaButton>
        </div>
      </ModalComponent>
      <ModalComponent
        open={openDeleteModal}
        handleClose={handleCloseDeleteModal}
        title="Confirmar Eliminación"
        actions={
          <>
            <LaddaButton variant="contained" onClick={handleDeleteActivity}>
              <p className="button_text">Sí, Eliminar</p>
            </LaddaButton>
            <LaddaButton variant="outlined" onClick={handleCloseDeleteModal}>
              <p className="button_text">Cancelar</p>
            </LaddaButton>
          </>
        }
      >
        <p>¿Está seguro que desea eliminar esta actividad?</p>
      </ModalComponent>
    </div>
  );
};

export default InspectionProtocol;
