import React from "react";
import BreadCrumbComp from "../../components/breadCrumb";
import BiomedicalEquipmentBase from "./biomedicalEquipmentBase";

const RegisterBiomedicalEquipment = () => {
  return (
    <div>
      <BreadCrumbComp />
      <div className="searchTitle">
        <h1>DAR ALTA A EQUIPO BIOMÉDICO</h1>
      </div>
      <div className="create-form-container">
        <BiomedicalEquipmentBase />
      </div>
    </div>
  );
};

export default RegisterBiomedicalEquipment;
