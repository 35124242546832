import React, { useEffect, useState } from "react";
import ModalComponent from "../../../components/modals";
import LaddaButton from "../../../components/buttons/laddaButton";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import SimpleTable from "../../../components/tables/simpleTable";
import { callApiAsync, getCallSeries } from "../../../services/apiService";
import { useDispatch } from "react-redux";
import { setEquipementSerieId } from "../../../redux/slices/biomedicalSlice";

const validationSchema = yup.object().shape({});

const SelectSerieNumber = ({
  open,
  handleClose,
  setSerieIdSelected,
  setSerieSelected,
}) => {
  const dispatch = useDispatch();

  const [selectedRow, setSelectedRow] = useState(null);
  const [serieNumbers, setSerieNumbers] = useState([]);
  const [filter, setfilter] = useState("");
  const [selectionMade, setSelectionMade] = useState(false);

  const handleSetBiomedicalId = (data) => {
    dispatch(setEquipementSerieId(data));
  };

  const { control, setValue, reset } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: { search: "" },
  });

  const getAllSeries = () => {
    callApiAsync(getCallSeries, (data) => {
      setSerieNumbers(data);
    });
  };

  useEffect(() => {
    if (open) {
      getAllSeries();
    }
  }, [open]);

  const handleModalClose = () => {
    handleClose();
    setSelectedRow(null);
    setfilter("");
    reset();
  };

  return (
    <div>
      <ModalComponent
        open={open}
        handleClose={handleModalClose}
        title={""}
        actions={<div className="HContainer j-center w-complete"></div>}
      >
        <div className="HContainer">
          <div className="VContainer align-center">
            <SimpleTable
              data={serieNumbers}
              columns={[
                { field: "checkBox", header: "", columnType: "checkBox" },
                { field: "name", header: "Nro de Serie", columnWidth: "100px" },
              ]}
              onRowSelect={(row) => {
                setSelectedRow(row);
                setSelectionMade(true);
              }}
              tableStyle={{ width: "300px" }}
              isPaginated={true}
              globalFilterFields={["name"]}
              emptyMessage="No se encontraron coincidencias con los números de serie"
            />

            <LaddaButton
              width={"180px"}
              fontSize={"12px"}
              height={"32px"}
              variant="contained"
              margin={"20px 0"}
              onClick={() => {
                if (selectedRow) {
                  setSerieIdSelected(selectedRow.value);
                  handleSetBiomedicalId(selectedRow.value);
                  setSerieSelected(selectedRow.name);
                  setSelectionMade(true);
                  handleModalClose();
                }
              }}
              disabled={!selectedRow}
            >
              <p className="button_text">Seleccionar Nro de Serie</p>
            </LaddaButton>
          </div>
        </div>
      </ModalComponent>
    </div>
  );
};

export default SelectSerieNumber;
