import React, { useState } from "react";
import ModalComponent from "../../../components/modals";
import LaddaButton from "../../../components/buttons/laddaButton";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Grid } from "@mui/material";
import FormTextField from "../../../components/textField/formTextField";
import {
  callApiAsync,
  getCallCities,
  postSaveInstitution
} from "../../../services/apiService";
import SimpleSelect from "../../../components/selects/simpleSelect";
import { technologyModalityOption } from "../../../constants/selectData";

const validationSchema = yup.object().shape({});

const AddInstitution = ({
  open,
  close,
  departmentOptions,
  getInstitutionSelect
}) => {
  const [cityOptions, setCityOptions] = useState([]);

  const {
    control,
    handleSubmit,
    reset,
    getValues,
    formState: { errors, isValid }
  } = useForm({
    resolver: yupResolver(validationSchema)
  });

  const handleClose = () => {
    reset();
    close();
  };

  const getCities = () => {
    const departmentId = getValues("department");
    if (departmentId != undefined) {
      callApiAsync(
        () => getCallCities(departmentId),
        (data) => {
          setCityOptions(data);
        }
      );
    }
  };
  const onSubmit = () => {
    var objToSend = {
      institutionName: getValues("institutionName"),
      technologyModality: getValues("technologyModality"),
      departmentId: getValues("department"),
      cityId: getValues("city"),
      phone: getValues("phone"),
      address: getValues("address")
    };

    callApiAsync(
      () => postSaveInstitution(objToSend),
      () => {
        getInstitutionSelect();
        handleClose();
      }
    );
  };

  return (
    <div>
      <ModalComponent
        open={open}
        handleClose={() => {
          handleClose();
        }}
        title={""}
        actions={<div className="HContainer j-center w-complete"></div>}
      >
        <div className="HContainer">
          <form
            className="VContainer align-center m-20"
            onSubmit={handleSubmit((formData) => onSubmit(formData))}
          >
            <Grid container justifyContent="center" spacing={2}>
              <Grid item xs={12} md={6}>
                <p className="create-label">Nombre de la Institución:</p>
                <FormTextField
                  name="institutionName"
                  control={control}
                  label=""
                  error={errors.institutionName}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <p className="create-label">Modalidad de la Tecnología:</p>

                <SimpleSelect
                  name="technologyModality"
                  control={control}
                  options={technologyModalityOption}
                  hideLabel={true}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <p className="create-label">Departamento:</p>
                <SimpleSelect
                  name="department"
                  control={control}
                  options={departmentOptions}
                  hideLabel={true}
                  onChange={getCities}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <p className="create-label">Ciudad:</p>
                <SimpleSelect
                  name="city"
                  control={control}
                  options={cityOptions}
                  hideLabel={true}
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <p className="create-label">Teléfono:</p>
                <FormTextField
                  name="phone"
                  control={control}
                  label=""
                  error={errors.phone}
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <p className="create-label">Dirección:</p>
                <FormTextField
                  name="address"
                  control={control}
                  label=""
                  error={errors.address}
                />
              </Grid>
            </Grid>
            <LaddaButton
              width={"180px"}
              fontSize={"12px"}
              height={"32px"}
              variant="contained"
              margin={"20px 0"}
              type="submit"
              disabled={!isValid}
            >
              <p className="button_text">Crear Institución</p>
            </LaddaButton>
          </form>
        </div>
      </ModalComponent>
    </div>
  );
};

export default AddInstitution;
