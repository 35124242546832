import React from "react";
import SignComponent from "../../../components/signComponent";
import { Grid } from "@mui/material";

const ElectronicSignature = ({ setSignature, base64Signature, isApprover }) => {
  return (
    <Grid container justifyContent="center" spacing={2}>
      <Grid item xs={12} md={6}>
        <p className="createRist-title mt-25">5. Firma electrónica</p>
        <SignComponent
          setSignature={setSignature}
          base64Signature={base64Signature}
          isApprover={isApprover}
        />
      </Grid>
      <Grid item xs={12} md={6}></Grid>
    </Grid>
  );
};

export default ElectronicSignature;
