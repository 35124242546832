import React, { useEffect, useState } from "react";
import VerticalTab from "../../components/tabComponent/verticalTab";
import FormTextField from "../../components/textField/formTextField";
import { useForm } from "react-hook-form";
import { Grid } from "@mui/material";
import LaddaButton from "../../components/buttons/laddaButton";
import SimpleSelect from "../../components/selects/simpleSelect";
import {
  getCurrentMonth,
  getCurrentYear,
  getLastMonth,
} from "../../utils/dateUtils";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  callApiAsync,
  postCreateUpdateReport,
} from "../../services/apiService";

const validationSchema = yup.object().shape({
  analisis: yup.string().required("Este campo es obligatorio"),
});

const FormComponent = ({
  currentTab,
  currentYear,
  currentMonth,
  getPowerBiDetailsByIdAndUserId,
  infoPowerBiDetail,
  userId,
  valueTab,
  setValueTab,
  powerBiReport,
}) => {
  const { control: filterControl, getValues: getfilterValues } = useForm();

  const {
    control,
    getValues,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const [monthFilter, setMonthFilter] = useState([]);

  useEffect(() => {
    const monthDefault = generateMonthsArray(getCurrentYear());
    setMonthFilter(monthDefault);
  }, []);

  useEffect(() => {
    reset();
    const filterValues = getfilterValues();
    const selectedMonth = filterValues.month;
    const selectedYear = filterValues.year;
    getPowerBiDetailsByIdAndUserId(selectedMonth, selectedYear, valueTab);
  }, [valueTab]);

  useEffect(() => {
    loadData();
  }, [powerBiReport]);

  const loadData = () => {
    setValue(
      "analisis",
      powerBiReport && powerBiReport.analysis ? powerBiReport.analysis : ""
    );
    setValue(
      "actionPlan",
      powerBiReport && powerBiReport.commitment ? powerBiReport.commitment : ""
    );
  };

  function generateYearsArray() {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let year = 2023; year <= currentYear; year++) {
      years.push({ name: year.toString(), value: year });
    }
    return years;
  }

  const createUpdateReport = async (tabId, analysis, commitment) => {
    const filterValues = getfilterValues();

    const comment = commitment.length == 0 ? null : commitment;
    const id =
      powerBiReport && powerBiReport.reportPbiId
        ? powerBiReport.reportPbiId
        : 0;

    const objRequest = {
      id: id,
      userId: userId,
      processPbiId: tabId,
      analysis: analysis,
      commitment: commitment,
      month: filterValues.month,
      year: filterValues.year,
    };

    callApiAsync(() => postCreateUpdateReport(objRequest));
  };

  const handleFilterChange = () => {
    reset();
    const formData = getfilterValues();
    const monthData = generateMonthsArray(formData.year);
    setMonthFilter(monthData);
    if (valueTab == null) return;
    getPowerBiDetailsByIdAndUserId(formData.month, formData.year, valueTab);
  };

  function generateMonthsArray(selectedYear) {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1;

    const months = [];
    if (selectedYear === currentYear) {
      months.push(...monthsUntilCurrentMonth(currentMonth - 1));
    } else {
      months.push(...allMonths());
    }
    return months;
  }

  function monthsUntilCurrentMonth(currentMonth) {
    return allMonths().slice(0, currentMonth);
  }

  const handleTabSubmit = (tab) => {
    const analysis = getValues(`analisis`);
    const commitment = getValues(`actionPlan`);
    createUpdateReport(tab.id, analysis, commitment);
  };

  const RenderTabContent = ({ tab }) => {
    if (!tab) return <></>;
    const filterValues = getfilterValues();
    const selectedMonth = filterValues.month;
    const selectedYear = filterValues.year;

    const isDisabled =
      !(
        selectedMonth == getCurrentMonth() - 1 ||
        selectedMonth == getCurrentMonth() - 2
      ) || selectedYear !== currentYear;

    return (
      <div key={tab.id}>
        <form>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6}>
              <FormTextField
                name="analisis"
                control={control}
                formLabel=""
                label="Análisis:"
                isTextArea={true}
                maxLength={1000}
                disabled={isDisabled}
                error={errors.analisis}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormTextField
                name="actionPlan"
                control={control}
                formLabel=""
                label="Compromisos y plan de acción:"
                isTextArea={true}
                maxLength={1000}
                disabled={isDisabled}
              />
            </Grid>
          </Grid>
        </form>

        <LaddaButton
          variant="contained"
          onClick={handleSubmit(() => handleTabSubmit(tab))}
          type="button"
          width={"200px"}
          height={"42px"}
          disabled={isDisabled}
        >
          <div className="HContainer align-center">
            <p className="button_text">Guardar</p>
          </div>
        </LaddaButton>
      </div>
    );
  };

  return (
    <div className="VContainer align-center">
      <form>
        <div className="HContainer">
          <form
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <SimpleSelect
              name="year"
              control={filterControl}
              label="Año"
              options={generateYearsArray()}
              onChange={handleFilterChange}
              width={"200px"}
              defaultValue={currentYear}
            />
            <SimpleSelect
              name="month"
              control={filterControl}
              label="Mes"
              options={monthFilter}
              onChange={handleFilterChange}
              width={"200px"}
              defaultValue={getLastMonth()}
            />
          </form>
        </div>
      </form>
      <VerticalTab
        value={valueTab}
        setValue={setValueTab}
        panels={infoPowerBiDetail.map((tab) => ({
          ...tab,
        }))}
      >
        <RenderTabContent tab={currentTab} />
      </VerticalTab>
    </div>
  );
};

export default FormComponent;

function allMonths() {
  return [
    { name: "Enero", value: 1 },
    { name: "Febrero", value: 2 },
    { name: "Marzo", value: 3 },
    { name: "Abril", value: 4 },
    { name: "Mayo", value: 5 },
    { name: "Junio", value: 6 },
    { name: "Julio", value: 7 },
    { name: "Agosto", value: 8 },
    { name: "Septiembre", value: 9 },
    { name: "Octubre", value: 10 },
    { name: "Noviembre", value: 11 },
    { name: "Diciembre", value: 12 },
  ];
}
