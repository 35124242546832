import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";

const ToastNotification = ({
  severity,
  summary,
  detail,
  showToast,
  setShowToast,
  position = "top-right",
  zIndex = 9999
}) => {
  const toastRef = useRef(null);

  useEffect(() => {
    if (showToast) {
      toastRef.current.show({ severity, summary, detail });
      setShowToast(false);
    }
  }, [showToast, severity, summary, detail, setShowToast]);

  return (
    <div
      style={{
        position: "relative",
        zIndex: zIndex
      }}
    >
      <Toast ref={toastRef} position={position} />
    </div>
  );
};

export default ToastNotification;
