import React, { useEffect, useRef, useState } from "react";
import LaddaButton from "../buttons/laddaButton";
import ToastNotification from "../toastNotification";
import { useMediaQuery } from "@mui/material";

const SignComponent = ({ setSignature, base64Signature , isApprover}) => {
  const isSmallScreen = useMediaQuery("(max-width:590px)");
  const canvasRef = useRef(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastConfig, setToastConfig] = useState({
    severity: "success",
    summary: "",
    detail: "",
  });

  useEffect(() => {
    if (base64Signature && canvasRef.current) {
      const context = canvasRef.current.getContext("2d");
      const image = new Image();

      image.onload = () => {
        context.clearRect(
          0,
          0,
          canvasRef.current.width,
          canvasRef.current.height
        );
        context.drawImage(
          image,
          0,
          0,
          canvasRef.current.width,
          canvasRef.current.height
        );
      };

      image.onerror = (error) => {
        console.error("Error al cargar la firma en el canvas:", error);
        setToastConfig({
          severity: "error",
          summary: "Error al cargar firma",
          detail: "No se pudo cargar la firma en el canvas.",
        });
        setShowToast(true);
      };

      image.src = base64Signature;
    }
  }, [base64Signature]);

  const handleClear = () => {
    const context = canvasRef.current.getContext("2d");
    context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    setToastConfig({
      severity: "info",
      summary: "Acción realizada",
      detail: "Firma limpiada con éxito.",
    });
    setShowToast(true);
  };

  const handleSaveSignature = () => {
    const dataUrl = canvasRef.current.toDataURL("image/png");
    setSignature(dataUrl);
    setToastConfig({
      severity: "success",
      summary: "Acción realizada",
      detail: "Firma guardada con éxito.",
    });
    setShowToast(true);
  };

  const startDrawing = (e) => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    context.beginPath();
    context.moveTo(e.nativeEvent.offsetX, e.nativeEvent.offsetY);
    setIsDrawing(true);
  };

  const draw = (e) => {
    if (!isDrawing) return;

    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    context.lineTo(e.nativeEvent.offsetX, e.nativeEvent.offsetY);
    context.stroke();
  };

  const stopDrawing = () => {
    setIsDrawing(false);
  };

  return (
    <div className="column-center">
      <div className="hContainer j-center">
        <canvas
          ref={canvasRef}
          width={isSmallScreen ? 300 : 450}
          height={isSmallScreen ? 300 : 300}
          style={{ border: "1px solid #ccc", borderRadius: "4px" }}
          onMouseDown={startDrawing}
          onMouseMove={draw}
          onMouseUp={stopDrawing}
          onMouseLeave={stopDrawing}
        />
      </div>

      <div>
        <div
          className="container-inputs"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingBottom: "30px",
          }}
        >
          {!isApprover && (
            <>
              <LaddaButton
                fontSize={"12px"}
                height={"40px"}
                variant="contained"
                onClick={handleClear}
              >
                <p className="button_text">Limpiar</p>
              </LaddaButton>
              <LaddaButton
                width={"160px"}
                fontSize={"12px"}
                height={"40px"}
                variant="contained"
                onClick={handleSaveSignature}
              >
                <p className="button_text">Guardar</p>
              </LaddaButton>
            </>
          )}
        </div>
      </div>

      <ToastNotification
        severity={toastConfig.severity}
        summary={toastConfig.summary}
        detail={toastConfig.detail}
        showToast={showToast}
        setShowToast={setShowToast}
        position="top-right"
      />
    </div>
  );
};

export default SignComponent;
