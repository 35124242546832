import React, { useEffect, useState } from "react";
import ModalComponent from "../../../components/modals";
import LaddaButton from "../../../components/buttons/laddaButton";
import { useForm } from "react-hook-form";
import { Grid } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import SimpleSelect from "../../../components/selects/simpleSelect";
import {
  callApiAsync,
  getCallInventoryFile,
  getCallSelectYear,
} from "../../../services/apiService";

const validationSchema = yup.object().shape({
  effective: yup.boolean().required("Este campo es obligatorio"),
});

const InventaryDownload = ({ open, handleClose }) => {
  const [years, setYears] = useState([]);

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {},
  });

  useEffect(() => {
    if (!open) return;
    callApiAsync(
      () => getCallSelectYear(),
      (data) => {
        setYears(data);
      }
    );
  }, [open]);

  const downloadInventory = async () => {
    var year = getValues("actionState");
    callApiAsync(async () => {
      const response = await getCallInventoryFile(year);
      if (response.data instanceof Blob) {
        const url = window.URL.createObjectURL(response.data);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "inventory_report.xlsx");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        console.error("No se recibió un Blob:", response.data);
      }
    });
  };

  const handleCloseModal = () => {
    setValue("actionState","")
    handleClose();
  }

  return (
    <div>
      <ModalComponent
        open={open}
        handleClose={handleCloseModal}
        title={""}
        actions={
          <div className="HContainer j-center w-complete mv-25">
            <LaddaButton variant="contained" onClick={downloadInventory}>
              <div className="HContainer align-center">
                <p className="button_text">Descargar</p>
              </div>
            </LaddaButton>
          </div>
        }
      >
        <form className="VContainer small-modal m-20">
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <p className="detail-label">Año del inventario:</p>
              <SimpleSelect
                name="actionState"
                control={control}
                hideLabel={true}
                options={years}
              />
            </Grid>
          </Grid>
        </form>
      </ModalComponent>
    </div>
  );
};

export default InventaryDownload;
