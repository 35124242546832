import React, { useState } from "react";
import ModalComponent from "../../../components/modals";
import LaddaButton from "../../../components/buttons/laddaButton";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import FormTextField from "../../../components/textField/formTextField";
import {
  callApiAsync,
  postSaveResumeFeature
} from "../../../services/apiService";
import ToastNotification from "../../../components/toastNotification";

const validationSchema = yup.object().shape({
  newAccessory: yup
    .string()
    .required("Este campo es obligatorio")
    .min(1, "Este campo no puede estar vacío")
});

const AddFeature = ({ open, close, setFeatureOptions, featureOptions }) => {
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors, isValid }
  } = useForm({
    resolver: yupResolver(validationSchema)
  });

  const handleCloseModal = () => {
    reset();
    setValue("newFeature", "");
    close();
  };

  const handleAddNewFeature = () => {
    const newFeatureName = getValues("newFeature").trim().toLowerCase();

    const isDuplicate = featureOptions.some(
      (feature) => feature.name.trim().toLowerCase() === newFeatureName
    );

    if (isDuplicate) {
      setToastMessage("Ya existe la característica que intentas crear.");
      setShowToast(true);
      return;
    }

    const objToSend = {
      name: newFeatureName
    };

    callApiAsync(
      () => postSaveResumeFeature(objToSend),
      (data) => {
        if (data.answer) {
          setFeatureOptions(data.answer.list);
          setToastMessage("Nueva característica agregada.");
          setShowToast(true);
        } else {
          setToastMessage("Error al agregar la nueva característica.");
          setShowToast(true);
        }
      }
    );

    setValue("newFeature", "");
    handleCloseModal();
  };

  return (
    <div>
      <ModalComponent
        open={open}
        handleClose={handleCloseModal}
        title="Agregar Nueva Característica"
        actions={
          <>
            <LaddaButton variant="contained" onClick={handleAddNewFeature}>
              <p className="button_text">Agregar</p>
            </LaddaButton>
          </>
        }
      >
        <ToastNotification
          severity="warn"
          summary={toastMessage}
          detail=""
          showToast={showToast}
          setShowToast={setShowToast}
        />
        <FormTextField
          name="newFeature"
          control={control}
          label="Nueva Característica"
          error={errors.newFeature}
        />
      </ModalComponent>
    </div>
  );
};

export default AddFeature;
