import React from "react";
import ModalComponent from "../../../components/modals";
import { Grid } from "@mui/material";
import { format } from "date-fns";

const DeviceDetails = ({ open, handleClose, data }) => {
  // Verifica que los valores de fecha existan antes de formatear
  const formattedInstallationDate = data.installation_date
    ? format(new Date(data.installation_date), "dd-MM-yyyy")
    : "No disponible";

  const formattedMarketingDate = data.marketing_date
    ? format(new Date(data.marketing_date), "dd-MM-yyyy")
    : "No disponible";

  return (
    <div>
      <ModalComponent
        open={open}
        handleClose={handleClose}
        title={"Detalles del Equipo"}
        actions={<div className="HContainer j-center w-complete"></div>}
      >
        <Grid container rowSpacing={2} className="mv-15">
          <Grid item xs={12} sm={6} md={4}>
            <p className="detail-label">Nro. de Serie</p>
            <p>{data.serie_name}</p>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <p className="detail-label">Nro. de Serie</p>
            <p>{data.serie_name}</p>
          </Grid>
        </Grid>
        <Grid container rowSpacing={2} className="mv-15">
          <Grid item xs={12} sm={6} md={4}>
            <p className="detail-label">Importador</p>
            <p>{data.importer_name}</p>
          </Grid>

        </Grid>
      </ModalComponent>
    </div>
  );
};

export default DeviceDetails;
