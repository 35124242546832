import React, { useState } from "react";
import ModalComponent from "../../../components/modals";
import LaddaButton from "../../../components/buttons/laddaButton";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Grid } from "@mui/material";
import FormTextField from "../../../components/textField/formTextField";
import {
  callActionApiAsync,
  callApiAsync,
  postAddBiomedicalUbication
} from "../../../services/apiService";
import ToastNotification from "../../../components/toastNotification";

const validationSchema = yup.object().shape({
  newUbication: yup.string().required("Este campo es obligatorio")
});

const AddUbicationModal = ({ open, close, getSelectsBiomedicalData }) => {
  const [showToast, setShowToast] = useState(false);
  const [toastConfig, setToastConfig] = useState({
    severity: "warning",
    summary: "",
    detail: ""
  });

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema)
  });

  const handleClose = () => {
    reset();
    close();
  };

  const onSubmitNewUbication = async (formData) => {
    await callActionApiAsync(
      async () => await postAddBiomedicalUbication(formData),
      () => {
        getSelectsBiomedicalData();
        reset();
        handleClose();
        close();
      },
      () => {
        setToastConfig({
          severity: "error",
          summary: "Error de creación",
          detail: "Esta ubicación ya existe."
        });
        setShowToast(true);
      }
    );
  };

  return (
    <ModalComponent
      open={open}
      handleClose={handleClose}
      title=""
      actions={<div className="HContainer j-center w-complete"></div>}
    >
      <div className="HContainer">
        <form
          className="VContainer align-center m-20"
        >
          <Grid container rowSpacing={2}>
            <Grid item xs={12}>
              <p className="create-label">Nueva Ubicación:</p>
              <FormTextField
                name="newUbication"
                control={control}
                error={errors.newUbication}
              />
            </Grid>
          </Grid>
          <LaddaButton
            width={"180px"}
            fontSize={"12px"}
            height={"32px"}
            variant="contained"
            margin={"20px 0"}
            type="button"
            onClick={handleSubmit(onSubmitNewUbication)}
          >
            <p className="button_text">Agregar Ubicación</p>
          </LaddaButton>
        </form>
      </div>
      <ToastNotification
        severity={toastConfig.severity}
        summary={toastConfig.summary}
        detail={toastConfig.detail}
        showToast={showToast}
        setShowToast={setShowToast}
        position="top-right"
      />
    </ModalComponent>
  );
};

export default AddUbicationModal;
