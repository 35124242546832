import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  biomedicalId: null,
  equipementSerieId: null,
  inspectionRecord: null,
};

const dataSlice = createSlice({
  name: "biomedical",
  initialState,
  reducers: {
    setBiomedicalId: (state, action) => {
      state.biomedicalId = action.payload;
    },
    resetBiomedicalId: (state) => {
      state.biomedicalId = null;
      state.inspectionRecord = null;
      state.equipementSerieId = null;
    },
    setEquipementSerieId: (state, action) => {
      state.equipementSerieId = action.payload;
    },
    resetEquipementSerieId: (state) => {
      state.equipementSerieId = null;
    },
    setInspectionRecordId: (state, action) => {
      state.inspectionRecord = action.payload;
    },
  },
});

export const {
  setBiomedicalId,
  resetBiomedicalId,
  setEquipementSerieId,
  setInspectionRecordId,
  resetEquipementSerieId,
} = dataSlice.actions;

export default dataSlice.reducer;
