import React, { useEffect, useState } from "react";
import axios from "axios";
import LaddaButton from "../../components/buttons/laddaButton";
import { icons } from "../../constants";
import BreadCrumbComp from "../../components/breadCrumb";
import SimpleTable from "../../components/tables/simpleTable";
import { dasboardBioMedicalBurnData } from "../../utils/burnData";
import { useForm } from "react-hook-form";
import { Grid, useMediaQuery } from "@mui/material";
import SimpleSelect from "../../components/selects/simpleSelect";
import FormTextField from "../../components/textField/formTextField";
import { getCookie } from "../../utils/getCookie";
import { API_URL } from "../../config";
import InventaryDownload from "./modals/inventaryDownload";
import DeviceDetails from "./modals/deviceDetails";
import { Link, useNavigate } from "react-router-dom";
import EditDevice from "./modals/editDevice";
import { useDispatch, useSelector } from "react-redux";
import {
  resetBiomedicalId,
  resetEquipementSerieId,
  setBiomedicalId,
  setEquipementSerieId
} from "../../redux/slices/biomedicalSlice";
import {
  callApiAsync,
  getDevicesInventoryFilters,
  getDevicesInventoryNotifications
} from "../../services/apiService";
import MenuButton from "../../components/menuButton";
import ModalComponent from "../../components/modals";
import { isRoleUtil } from "../../utils/AuthorizedUtils/360Report";
import { inspectionRecordProcess } from "../../constants/enums";
import { technologyModalityOption } from "../../constants/selectData";

const ManageDevice = () => {
  const dispatch = useDispatch();
  const userSesion = getCookie("sesion");
  const userId = userSesion.id_usuario;
  const userData = useSelector((state) => state.userData.userData);

  const [devicesTable, setDevicesTable] = useState([]);
  const [filteredDevices, setFilteredDevices] = useState([]);
  const [inventaryDownloadModal, setInventaryDownloadModal] = useState(false);
  const [editDeviceModal, setEditDeviceModal] = useState(false);
  const [notificationDataModal, setNotificationDataModal] = useState(false);
  const [detailsModal, setDetailsModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [modelFilter, setModelFilter] = useState([]);

  const [bioEquipmentIdState, setBioEquipmentIdState] = useState(null);
  const [notification, setNotification] = useState([]);

  const isSmallScreen = useMediaQuery("(max-width:590px)");
  const tableColumns = dasboardBioMedicalBurnData(isSmallScreen);

  const { control: controlTable, getValues: getValuesTable, reset } = useForm();

  const { control: notificationControl, setValue: notificationSetValue } =
    useForm({
      defaultValues: {
        coment: ""
      }
    });

  dispatch(resetEquipementSerieId());
  dispatch(resetBiomedicalId());

  const navigate = useNavigate();

  const getDevicesInventory = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/getDevicesInventory/${userId}`
      );
      const data = response.data;
      setDevicesTable(data);
      setFilteredDevices(data);
      notificationSetValue(data.comments);
    } catch (error) {
      console.error("Error fetching devices:", error);
    }
  };

  const getFilters = () => {
    callApiAsync(
      () => getDevicesInventoryFilters(userId),
      (data) => {
        setModelFilter(data);
      }
    );
  };

  const getNotification = () => {
    callApiAsync(
      () => getDevicesInventoryNotifications(userId),
      (data) => {
        const filteredData = data.filter((item) => {
          if (isApprover) {
            return item.state == inspectionRecordProcess.sent;
          } else {
            return (
              item.state === inspectionRecordProcess.sent ||
              item.state === inspectionRecordProcess.pending
            );
          }
        });

        setNotification(filteredData);
      }
    );
  };

  const handleFilter = () => {
    const filters = getValuesTable();
    const filtered = devicesTable.filter((device) => {
      return (
        (filters.equipment === "" || device.name.includes(filters.equipment)) &&
        (filters.serieNumber === "" ||
          device.serie_name.toString() === filters.serieNumber) &&
        (filters.model === "" || device.model.includes(filters.model)) &&
        (filters.mode === "" || device.state_id === filters.mode)
      );
    });

    setFilteredDevices(filtered);
  };

  const handleClearFilters = () => {
    reset();
    setFilteredDevices(devicesTable);
  };

  const handleCloseModals = () => {
    setInventaryDownloadModal(false);
    setDetailsModal(false);
    setEditDeviceModal(false);
    setSelectedRow({});
  };

  useEffect(() => {
    getFilters();
    getNotification();
    getDevicesInventory();
  }, []);

  var isApprover = isRoleUtil(
    "APPROVER_TECHNICAL_INSPECTION",
    userData.roleList
  );

  const handlePillClick = (comment, biomedical_id) => {
    notificationSetValue("coment", comment);
    setBioEquipmentIdState(biomedical_id);
    setNotificationDataModal(true);
  };

  const handleRedirect = (biomedical_id) => {
    dispatch(setBiomedicalId(biomedical_id));
    navigate(
      "/biomedicalManagementDashboard/manageDevice/NewTechnicalInspectionAct"
    );
  };

  const filteredNotifications = notification.filter((item) => {
    if (isApprover) {
      return item.state == inspectionRecordProcess.sent;
    } else {
      return (
        item.state === inspectionRecordProcess.sent ||
        item.state === inspectionRecordProcess.pending
      );
    }
  });

  return (
    <div>
      <BreadCrumbComp />
      <div className="searchTitle align-center">
        <Grid justifyContent="space-between" container spacing={1.5}>
          <Grid item xs={12} md={8}>
            <h1>CREAR/DAR DE ALTA UN DISPOSITIVO O EQUIPO BIOMÉDICO</h1>
          </Grid>
          <Grid item xs={12} md={4}>
            <MenuButton
              title="Alertas Acta IT"
              notification={filteredNotifications.length}
            >
              <p className="not-title">Acta de Inspección Técnica</p>
              {notification.map((item) => {
                let statusText = "";
                let statusColor = "";

                switch (item.state) {
                  case 1:
                    statusText = "Rechazado";
                    statusColor = "#F43F5EB8";
                    break;
                  case 2:
                    statusText = isRoleUtil(
                      "APPROVER_TECHNICAL_INSPECTION",
                      userData.roleList
                    )
                      ? "Para Aprobación"
                      : "Esperando Aprobación";
                    statusColor = "#F8A13FA3";
                    break;
                  default:
                    statusText = "Desconocido";
                    statusColor = "gray";
                    break;
                }

                const shouldDisplayItem =
                  item.state !== 0 ||
                  !isRoleUtil(
                    "APPROVER_TECHNICAL_INSPECTION",
                    userData.roleList
                  );

                if (!shouldDisplayItem) {
                  return null;
                }

                return (
                  <div
                    className="HContainer j-space-between w-180"
                    key={item.actId}
                  >
                    <p className="not-id-text">{item.name}</p>
                    <div
                      className="pill-layout mt-5 w-75"
                      onClick={() => {
                        if (isApprover) {
                          handleRedirect(item.biomedical_equipment_id);
                        } else {
                          if (item.state === inspectionRecordProcess.pending) {
                            handlePillClick(
                              item.comments,
                              item.biomedical_equipment_id
                            );
                          }
                        }
                      }}
                      style={{
                        backgroundColor: statusColor,
                        fontWeight: "bold",
                        cursor: item.state === 0 ? "pointer" : "default"
                      }}
                    >
                      <p className="not-status-text">{statusText}</p>
                    </div>
                  </div>
                );
              })}
            </MenuButton>
          </Grid>
        </Grid>
      </div>

      <div className="table-container">
        <div className="HContainer j-space-between">
          <Grid justifyContent="space-between" container spacing={1.5}>
            <Grid item xs={12} md={6}>
              <h1 className="title-bio-details-chard">
                Inventario de dispositivos biomédicos
              </h1>
            </Grid>
            <Grid item xs={12} md={6}>
              <div className="HContainer align-center">
                <LaddaButton
                  variant="contained"
                  height={isSmallScreen ? "48px" : "32px"}
                  onClick={() => setInventaryDownloadModal(true)}
                >
                  <div className="HContainer align-center">
                    <img
                      className="btn-icon"
                      src={icons.downloadIcon}
                      alt="icon"
                      style={{ marginRight: "10px" }}
                    />
                    <p className="button_text">Descargar inventario</p>
                  </div>
                </LaddaButton>
                <LaddaButton
                  variant="contained"
                  height={isSmallScreen ? "48px" : "32px"}
                >
                  <Link
                    to={{
                      pathname:
                        "/biomedicalManagementDashboard/manageDevice/registerBiomedicalEquipment",
                      state: { newItem: true }
                    }}
                    className="no-underline"
                  >
                    <div className="HContainer align-center">
                      <img
                        className="btn-icon"
                        src={icons.addPlus}
                        alt="icon"
                        style={{ marginRight: "10px" }}
                      />
                      <p className="button_text white-text">
                        Agregar dispositivo
                      </p>
                    </div>
                  </Link>
                </LaddaButton>
              </div>
            </Grid>
          </Grid>
        </div>

        <br className="br-white-space" />

        <div className="mb-10" style={{ marginLeft: "-10px" }}>
          <form>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3} md={3}>
                <FormTextField
                  styledlabel="Equipo"
                  name="equipment"
                  control={controlTable}
                />
              </Grid>
              <Grid item xs={12} sm={3} md={3}>
                <FormTextField
                  styledlabel="Nro de Serie"
                  name="serieNumber"
                  control={controlTable}
                />
              </Grid>
              <Grid item xs={12} sm={3} md={2}>
                <SimpleSelect
                  name="model"
                  control={controlTable}
                  label="Modelo"
                  options={modelFilter}
                />
              </Grid>
              <Grid item xs={12} sm={3} md={2}>
                <SimpleSelect
                  name="mode"
                  control={controlTable}
                  label="Modalidad"
                  options={technologyModalityOption}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={2}>
                <div className="HContainer j-end">
                  <LaddaButton
                    variant="contained"
                    onClick={handleFilter}
                    margin={"0"}
                  >
                    <img
                      className="btn-icon"
                      src={icons.filterWhite}
                      alt="icon"
                      style={{ marginRight: "10px" }}
                    />
                  </LaddaButton>
                  <LaddaButton
                    variant="contained"
                    onClick={handleClearFilters}
                    margin={"0 0 0 10px"}
                  >
                    <img
                      className="btn-icon"
                      src={icons.clearFilterWhite}
                      alt="icon"
                    />
                  </LaddaButton>
                </div>
              </Grid>
            </Grid>
          </form>
        </div>
        <ModalComponent
          open={notificationDataModal}
          handleClose={() => {
            setNotificationDataModal(false);
          }}
          title={""}
          actions={<div className="HContainer j-center w-complete"></div>}
        >
          <Grid container className="mv-25 min-w-700">
            <Grid item xs={12} md={12}>
              <div className="VContainer j-center w-675">
                <p className="text-form-label" style={{ marginLeft: "10px" }}>
                  Comentario:
                </p>
                <FormTextField
                  name="coment"
                  control={notificationControl}
                  isTextArea={true}
                  rows={2}
                  disabled={true}
                />
              </div>
            </Grid>
          </Grid>
          <div className="j-center mt-20">
            <LaddaButton
              width={"110px"}
              fontSize={"12px"}
              height={"32px"}
              variant="contained"
              margin={"0 5px"}
              onClick={() => handleRedirect(bioEquipmentIdState)}
            >
              <div className="j-center">
                <p className="button_text">Modificar</p>
              </div>
            </LaddaButton>
          </div>
        </ModalComponent>

        <div>
          <SimpleTable
            data={
              filteredDevices.length > 0 || getValuesTable().length > 0
                ? filteredDevices
                : []
            }
            columns={tableColumns.columns}
            text={"Realizar Autoevaluación"}
            isPaginated={true}
            sortable
            frozen={isSmallScreen ? false : true}
            buttons={[
              {
                type: "icon",
                icon: icons.editYellow,
                onClick: (data) => {
                  setSelectedRow(data);
                  dispatch(setEquipementSerieId(data.serie_id));
                  navigate(
                    `/biomedicalManagementDashboard/manageDevice/registerBiomedicalEquipment`,
                    {
                      state: { deviceData: data }
                    }
                  );
                }
              },
              {
                type: "icon",
                icon: icons.orangeFormGoogle,
                onClick: (data) => {
                  dispatch(setEquipementSerieId(data.serie_id));
                  dispatch(setBiomedicalId(data.id));
                  navigate(
                    `/biomedicalManagementDashboard/manageDevice/BiomedicalEquipmentResume`,
                    {
                      state: { deviceData: data }
                    }
                  );
                }
              },
              {
                type: "icon",
                icon: icons.greengrayLupaGear,
                onClick: (data) => {
                  dispatch(setEquipementSerieId(data.serie_id));
                  dispatch(setBiomedicalId(data.id));
                  navigate(
                    `/biomedicalManagementDashboard/manageDevice/NewTechnicalInspectionAct`,
                    {
                      state: { deviceData: data }
                    }
                  );
                }
              }
            ]}
          />
        </div>
      </div>
      <DeviceDetails
        open={detailsModal}
        handleClose={handleCloseModals}
        data={selectedRow}
      />
      <InventaryDownload
        open={inventaryDownloadModal}
        handleClose={handleCloseModals}
      />
      <EditDevice
        open={editDeviceModal}
        handleClose={handleCloseModals}
        data={selectedRow}
      />
    </div>
  );
};

export default ManageDevice;
