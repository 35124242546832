import { Grid, useMediaQuery } from "@mui/material";
import React from "react";
import images from "../../constants/images";
import "./styles.css";

const InspectionActBanner = () => {
  const isSmallScreen = useMediaQuery("(max-width:590px)");

  return (
    <div className={`${isSmallScreen ? "VContainer" : "HContainer"} managmentBanner align-center`}>
      <div>
        <img className="banner-img" src={images.bannerLogo} alt="icon" />
      </div>
      <div style={{width: '100%'}}>
        <div className="bannerText-title-container-50">
          <p className="bannerText-title">PROCESO GESTION BIOMEDICA</p>
          {/* <p className="bannerText-title">IMPACTOS VS PROBABILIDAD</p> */}
        </div>
        <Grid container rowSpacing={2}>
          <Grid
            className="managmentBannerItem"
            item
            xs={11.8}
            sm={11.8}
            md={11.8}
          >
            <p className="bannerText">ALTA INSPECCIÓN TÉCNICA</p>
          </Grid>
          <Grid className="managmentBannerItem" item xs={3.9} sm={3.9} md={3.9}>
            <p className="bannerText">Código: R-GB-011</p>
          </Grid>
          <Grid className="managmentBannerItem" item xs={3.9} sm={3.9} md={3.9}>
            <p className="bannerText">Fecha: 08-02-2022 </p>
          </Grid>
          <Grid className="managmentBannerItem" item xs={3.9} sm={3.9} md={3.9}>
            <p className="bannerText">Versión: 02 </p>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default InspectionActBanner;
