import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import FormTextField from "../../../components/textField/formTextField";
import LaddaButton from "../../../components/buttons/laddaButton";
import ToastNotification from "../../../components/toastNotification";

import {
  callApiAsync,
  getCallAccessoryData,
  postAddAccessoryData,
} from "../../../services/apiService";
import SimpleSelect from "../../../components/selects/simpleSelect";
import { icons } from "../../../constants";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import AddAccessory from "../modals/addAccessory";
import ModalComponent from "../../../components/modals";

const validationSchema = yup.object({
  amountAccessory: yup
    .number()
    .required("La cantidad es obligatoria")
    .integer("La cantidad debe ser un número entero")
    .positive("La cantidad debe ser mayor a 0")
    .typeError("Este campo debe ser un número")
});

const SelectAccessoryModal = ({
  brandIdSelected,
  open,
  close,
  getAccessoryData,
  selectedRow,
  setSelectedRow,
  biomedicalId,
  accessoryTable,
}) => {
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [accessoryOptions, setAccessoryOptions] = useState([]);
  const [accessoriesBrandOptions, setAccessoriesBrandOptions] = useState([]);

  const [openAddAccessoryModal, setOpenAddAccessoryModal] = useState(false);

  const hadleCloseAddAccessory = () => {
    setOpenAddAccessoryModal(false);
  };

  const {
    control,
    formState: { errors },
    getValues,
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "all",
  });

  useEffect(() => {
    getAccessoryDataM();
  }, [brandIdSelected]);

  const getAccessoryDataM = () => {
    if (brandIdSelected == null) return;
    callApiAsync(
      () => getCallAccessoryData(brandIdSelected, biomedicalId),
      (data) => {
        setAccessoryOptions(data.list);
        setAccessoriesBrandOptions(data.listBrandSpecific);
      }
    );
  };

  useEffect(() => {
    getAccessoryData();
  }, []);

  useEffect(() => {
    if (!open) return;
    if (selectedRow) {
      loadData(selectedRow);
    } else {
      cleanData();
    }
  }, [open]);

  const handleAddAccessoryWithValidation = () => {
    const accessoryId = getValues("accessory");
    if (selectedRow === null) {
      const existingAccessory = accessoryTable.find(
        (item) => item.accessoryId === accessoryId
      );

      if (existingAccessory) {
        setToastMessage(
          "El accesorio ya se encuentra en la lista de accesorios añadidos."
        );
        setShowToast(true);
        return;
      }
    } else {
      const accessoryIdConflict = accessoryTable.some(
        (item) => item.accessoryId === accessoryId && item.id !== selectedRow.id
      );

      if (accessoryIdConflict) {
        setToastMessage(
          "Ya existe otro accesorio con el mismo ID en la lista."
        );
        setShowToast(true);
        return;
      }
    }
    const accessoryData = {
      id: selectedRow == null ? 0 : selectedRow.id,
      accessoryId: getValues("accessory"),
      brandSpecificId: getValues("brandAccessory"),
      partNumber: getValues("partNumberAccessory"),
      quantity: getValues("amountAccessory"),
      biomadicalEquipmentId: biomedicalId,
    };
    callApiAsync(
      () => postAddAccessoryData(accessoryData),
      () => {
        setToastMessage("Accesorio gregado exitosamente.");
        setShowToast(true);
        cleanData();
        getAccessoryData();
        handleClose();
      }
    );
  };

  const loadData = (data) => {
    setValue("accessory", data.accessoryId);
    setValue("brandAccessory", data.brandSpecificId);
    setValue("partNumberAccessory", data.partNumberAccessory);
    setValue("amountAccessory", data.quantity);
  };
  const cleanData = () => {
    setValue("accessory", "");
    setValue("brandAccessory", "");
    setValue("partNumberAccessory", "");
    setValue("amountAccessory", "");
  };

  const handleClose = () => {
    cleanData();
    setSelectedRow(null);
    reset();
    close();
  };
  return (
    <div>
      <ModalComponent
        open={open}
        handleClose={() => {
          handleClose();
        }}
        title={""}
        actions={<div className="HContainer j-center w-complete"></div>}
      >
        <div>
          <ToastNotification
            severity="warn"
            summary={toastMessage}
            detail=""
            showToast={showToast}
            setShowToast={setShowToast}
          />

          <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={8} md={4}>
              <p className="create-label ">Accesorio:</p>
              <SimpleSelect
                name="accessory"
                control={control}
                options={accessoryOptions}
                hideLabel={true}
              />
            </Grid>
            <Grid item xs={4} md={2} className="VContainer align-end">
              <div>
                <LaddaButton
                  width={"40px"}
                  fontSize={"12px"}
                  height={"40px"}
                  variant="contained"
                  margin={"0px 0px 0px 25px"}
                  onClick={() => {
                    if (brandIdSelected != null) {
                      setOpenAddAccessoryModal(true);
                    }
                  }}
                >
                  <img className="btn-icon" src={icons.plusIcon} alt="icon" />
                </LaddaButton>
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <p className="create-label">Marca:</p>
              <SimpleSelect
                name="brandAccessory"
                control={control}
                options={accessoriesBrandOptions}
                error={errors.brandAccessory}
                hideLabel={true}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <p className="create-label">Número de Parte:</p>
              <FormTextField
                name="partNumberAccessory"
                control={control}
                label=""
                error={errors.partNumberAccessory}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <p className="create-label">Cantidad:</p>
              <FormTextField
                name="amountAccessory"
                control={control}
                label=""
                error={errors.amountAccessory}
              />
            </Grid>
            <Grid item md={6}>
            </Grid>
          </Grid>
          {selectedRow == null && (
            <LaddaButton
              variant="contained"
              disabled={selectedRow != null}
              onClick={() => {
                handleAddAccessoryWithValidation();
              }}
            >
              <p className="button_text">Agregar Accesorio</p>
            </LaddaButton>
          )}
          {selectedRow != null && (
            <LaddaButton
              variant="contained"
              disabled={selectedRow == null}
              onClick={() => {
                handleAddAccessoryWithValidation();
              }}
            >
              <p className="button_text">Guardar</p>
            </LaddaButton>
          )}

          <AddAccessory
            open={openAddAccessoryModal}
            close={hadleCloseAddAccessory}
            setAccessoryOptions={setAccessoryOptions}
            brandIdSelected={brandIdSelected}
            accessoryOptions={accessoryOptions}
          />
        </div>
      </ModalComponent>
    </div>
  );
};

export default SelectAccessoryModal;
