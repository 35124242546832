import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import {
  actionButtonsColumn,
  defaultColumn,
  tooltipColumn,
  titlesColumn,
  pillsColumn,
  redirectionColumn,
  percentageBtnColumn,
  upDownNumberColumn,
  actionSelfApraisalButtonsColumn,
  editButtonsColumn,
  hideColumn,
  urlColumn,
  columnRight,
  expanderColumn,
  checkBoxColumn,
  counterColumn
} from "../columns";
import "./styles.css";
import { icons } from "../../../constants";
import { Column } from "primereact/column";

const burnDataColumns = [
  { field: "code", header: "Code" },
  { field: "name", header: "Name" }
];

const burnData = [
  {
    id: "1000",
    code: "f230fh0g3",
    name: "Bamboo Watch"
  },
  {
    id: "1013",
    code: "fldsmn31b",
    name: "Grey T-Shirt"
  }
];

const SimpleTable = ({
  columns = burnDataColumns,
  data = burnData,
  buttons,
  scrollHeight,
  text,
  onClickftn,
  hideBtn,
  isPaginated,
  tableStyle,
  tooltipFragment,
  rowExpansionTemplate,
  control,
  onRowSelect,
  globalFilterFields = [],
  emptyMessage = "No se han encontrado registros",
  frozen = false
}) => {
  const [expandedRows, setExpandedRows] = useState(null);
  const [globalFilter, setGlobalFilter] = useState("");
  const [selectedRow, setSelectedRow] = useState(null);
  const [rowClick, setRowClick] = useState(true);

  const handleSelectionChange = (e) => {
    setSelectedRow(e.value);
    onRowSelect(e.value);
  };

  const toast = useRef(null);

  const getColumnType = (item, i, arrayLength) => {
    const isLastItem = i === arrayLength.length - 1;

    const itemData = {
      index: i,
      lastItem: isLastItem,
      ...item
    };

    switch (item.columnType) {
      case "titles":
        return titlesColumn(itemData);
      case "counter":
        return counterColumn(itemData);
      case "hide":
        return hideColumn(itemData);
      case "expander":
        return expanderColumn(itemData);
      case "url":
        return urlColumn(itemData);
      case "pills":
        return pillsColumn(itemData);
      case "tooltip":
        return tooltipColumn(itemData, tooltipFragment);
      case "redirection":
        return redirectionColumn(itemData);
      case "percentageBtn":
        return percentageBtnColumn(itemData);
      case "upDownNumber":
        return upDownNumberColumn(itemData);
      case "actionSelfApraisalButtonsColumn":
        return actionSelfApraisalButtonsColumn(
          itemData,
          buttons,
          onClickftn,
          text,
          hideBtn
        );
      case "editButtonsColumn":
        return editButtonsColumn(itemData, buttons, onClickftn, text, data);
      case "actionButtons":
        return actionButtonsColumn(
          itemData,
          buttons,
          onClickftn,
          text,
          frozen
        );
      case "columnRight":
        return columnRight(itemData);
      case "fullText":
        return defaultColumn(itemData, true);
      case "checkBox":
        return checkBoxColumn(itemData);
      case "custom-frozen":
        return (
          <Column
            key={item.field}
            frozen
            header={item.header}
            headerClassName={`table-header-styled table-header-blueBlack ${
              item.borderRadius === "left"
                ? "table-header-rounded-left"
                : item.borderRadius === "right"
                ? "table-header-rounded-right"
                : ""
            }`}
            body={(rowData) => {
              return <div>{item.render(rowData, control)}</div>;
            }}
          />
        );
      case "custom":
        return (
          <Column
            key={item.field}
            header={item.header}
            headerClassName={`table-header-styled`}
            body={(rowData) => <div>{item.render(rowData, control)}</div>}
          />
        );
      default:
        return defaultColumn(itemData);
    }
  };

  const tableBody = document.querySelector(".p-datatable-tbody");
  if (tableBody) {
    const rows = tableBody.querySelectorAll("tr");
    if (rows.length > 0) {
      rows[rows.length - 1].classList.add("last-row");
    }
  }

  const header = (
    <div>
      {globalFilterFields.length > 0 && (
        <div className="p-inputgroup">
          <span className="p-inputgroup-addon">
            <img className="btn-icon" src={icons.lupablue} alt="icon" />
          </span>
          <InputText
            placeholder="Buscar..."
            value={globalFilter}
            onChange={(e) => setGlobalFilter(e.target.value)}
          />
        </div>
      )}
    </div>
  );

  return (
    <div className="card">
      <DataTable
        expandedRows={expandedRows}
        onRowToggle={(e) => setExpandedRows(e.data)}
        selectionMode={rowClick ? null : "radiobutton"}
        selection={selectedRow}
        onSelectionChange={handleSelectionChange}
        tableStyle={tableStyle}
        paginator={isPaginated}
        rows={10}
        scrollable
        scrollHeight={scrollHeight ?? "800px"}
        value={data}
        globalFilter={globalFilter}
        globalFilterFields={globalFilterFields}
        header={globalFilterFields.length > 0 ? header : null}
        showGridlines
        emptyMessage={<span>{emptyMessage}</span>}
      >
        {columns.map((col, i, arr) => getColumnType(col, i, arr))}
      </DataTable>
    </div>
  );
};

export default SimpleTable;
