import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import SimpleTable from "../../../components/tables/simpleTable";
import LaddaButton from "../../../components/buttons/laddaButton";
import ToastNotification from "../../../components/toastNotification";

import {
  callApiAsync,
  getCallAccessoryData
} from "../../../services/apiService";
import { biomedicalAccesoriesColumns } from "../columns";
import { icons } from "../../../constants";
import { useDispatch, useSelector } from "react-redux";
import SelectAccessoryModal from "../modals/selectAccessoryModal";

const AccesorySection = ({ brandIdSelected }) => {
  const dispatch = useDispatch();

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [accessoryTable, setAccessoryTable] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [openSelectAccessoryModal, setOpenSelectAccessoryModal] =
    useState(false);

  const biomedicalId = useSelector((state) => state.biomedical.biomedicalId);

  const hadleCloseSelectAccessory = () => {
    setOpenSelectAccessoryModal(false);
  };

  useEffect(() => {
    getAccessoryData();
  }, [brandIdSelected]);

  const getAccessoryData = () => {
    if (brandIdSelected == null) return;
    callApiAsync(
      () => getCallAccessoryData(brandIdSelected, biomedicalId),
      (data) => {
        setAccessoryTable(data.table);
      }
    );
  };

  useEffect(() => {
    getAccessoryData();
  }, []);

  return (
    <div>
      <ToastNotification
        severity="warn"
        summary={toastMessage}
        detail=""
        showToast={showToast}
        setShowToast={setShowToast}
      />

      <div className="j-end no-print">
          <LaddaButton
            width={"40px"}
            fontSize={"12px"}
            height={"40px"}
            variant="contained"
            margin={"0px 0px 0px 25px"}
            onClick={() => {
              if (brandIdSelected != null) {
                setSelectedRow(null);
                setOpenSelectAccessoryModal(true);
              } else {
                setToastMessage(
                  "Debe seleccionar una marca antes de continuar."
                );
                setShowToast(true);
              }
            }}
          >
            <img className="btn-icon" src={icons.plusIcon} alt="icon" />
          </LaddaButton>
      </div>

      <div className="mt-15">
        <SimpleTable
          data={accessoryTable}
          columns={biomedicalAccesoriesColumns}
          scrollHeight={"300px"}
          text={"Accesorios Agregados"}
          buttons={[
            {
              type: "icon",
              icon: icons.editYellow,
              onClick: (data) => {
                setSelectedRow(data);
                setOpenSelectAccessoryModal(true);
              }
            }
          ]}
        />
      </div>

      <SelectAccessoryModal
        open={openSelectAccessoryModal}
        close={hadleCloseSelectAccessory}
        getAccessoryData={getAccessoryData}
        brandIdSelected={brandIdSelected}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        biomedicalId={biomedicalId}
        accessoryTable={accessoryTable}
      />
    </div>
  );
};

export default AccesorySection;
